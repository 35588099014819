export const config = {
    api: {
        endpoint: "https://api.ovp.support",
        endpointUpload: "https://upload.ovp.support",
        endpointServerless: "https://serverless.ovp.support",
        endpointAWS: "https://d0upje2y8f.execute-api.us-east-1.amazonaws.com",
        storage_service: "imcinestorage",
        videos_service: "imcinevideos",
        images_service: "imcineimages",
        metadata_service: "imcinemetadata",
        mix_service: "imcinemix",
        auth_service: "imcineauthentication",
        login: "login",
        sessionInfo: "sessioninfo",
        languages: "listLanguages",
        qualities: "listQualities",
        clasifications: "listClassifications",
        genres: "listgenres",
        genders: "listGenders",
        moods: "listmoods",
        shortsAndFilms: "listShortsAndFilms",
        movieShortDetail: "movieShortDetail",
        createMultipart: "newmultipart",
        closeMultipart: "completemultipartvideo",
        uploadVideo: "uploadmultipart",
        uploadimage: "upload",
        createContent: "createMovieShortSeriesSeason",
        createMassiveContent: "createMassiveMovieShortSeriesSeason",
        createGenre: "creategenre",
        createtag: "createtag",
        updateContent: "updatecontentmetadata",
        updateTimeMark: "updateTimeMarks",
        updateClasifications: "updateClasification",
        simplecontentlist: "simplecontentlist",
        videosize: "videosize",
        hlsgenerator: "hlsgenerator",
        tagsByContent: "listtagstocontent",
        linkImage: "linkImageElement",
        listtags: "listtags",
        linkmoodtocontent: "linkmoodtocontent",
        unlinkmoodtocontent: "unlinkmoodtocontent",
        listgenrestocontent: "listgenrestocontent",
        linkgenretocontent: "linkgenretocontent",
        unlinkgenretocontent: "unlinkgenretocontent",
        linktagtocontent: "linktagtocontent",
        unlinktagtocontent: "unlinktagtocontent",
        listreleatedcontent: "listreleatedcontent",
        addrelatedcontent: "addrelatedcontent",
        removerelatedcontent: "removerelatedcontent",
        listdistributors: "listdistributor/",
        listdistributorsOnContent: "listdistributoroncontent/",
        linkdistributor: "linkdistributor/",
        unlinkdistributor: "unlinkdistributor/",
        listactors: "listactors",
        listactorstocontent: "listactorstocontent",
        linkactortocontent: "linkactortocontent",
        unlinkactortocontent: "unlinkactortocontent",
        listdirectors: "listdirectors",
        listdirectorstocontent: "listdirectorstocontent",
        linkdirectortocontent: "linkdirectortocontent",
        unlinkdirectortocontent: "unlinkdirectortocontent",
        countries: "countries",
        createdirector: "createdirector",
        updatedirector: "updatedirector",
        deletedirector: "deletedirector",
        createactor: "createactor",
        updateactor: "updateactor",
        listContentRights: "listContentRights",
        addRightsToContent: "addRightsToContent",
        deleteRightsToContent: "deleteRightsToContent",
        updateRightsToContent: "updateRightsToContent",
        listContentRightDetail: "listContentRightDetail",
        searchmoviesandshorts: "searchmoviesandshorts",
        deleteactor: "deleteactor",
        createdistributor: "createdistributor",
        updatedistributor: "updatedistributor",
        deletedistributor: "deletedistributor",
        listdistributor: "listdistributor",
        listContentGeorestriction: "listcontentgeorestriction",
        addcontentgeorestriction: "addcontentgeorestriction",
        removecontentgeorestriction: "removecontentgeorestriction",
        listContentBlocks: "listContentBlocks",
        createcontentblock: "createContentBlock",
        updatecontentblock: "updateContentBlock",
        listblockelements: "listblockelements",
        linkcontentblock: "linkcontentblock",
        unlinkcontentblock: "unlinkcontentblock",
        deletecontentblock: "deleteContentBlock",
        listMiniSites: "listMinisites",
        MiniSiteDetail: "listMinisiteDetail",
        linkContentBlockToMinisite: "linkContentBlockToMinisite",
        unlinkContentBlockToMinisite: "unlinkContentBlockToMinisite",
        deleteMiniSite: "deleteMiniSite",
        createMinisite: "createMinisite",
        sortMinisiteContentBlocks: "sortMinisiteContentBlocks",
        updateMinisite: "updateMinisite",
        listsubtitles: "listsubtitles",
        removesubtitle: "removesubtitle",
        imcineattachments: "imcineattachments",
        uploadattachment: "uploadattachment",
        linkSubtitle: "linkSubtitle",
        royaltyreport: "royaltyreport",
        serieslist: "serieslist",
        listContentMetadataSeasons: "listContentMetadataSeasons",
        deleteContentMetadataSeasons: "deleteContentMetadataSeasons",
        imcineusermanagementott: "imcineusermanagementott",
        listusers: "listusers",
        userDetail: "detail",
        suspendUser: "suspend",
        activateUser: "activate",
        closeSessions: "clear",
        userRecover: "recover",
        searchusers: "searchusers",
        payment_service: 'imcinepayment',
        listTrailers: 'listtrailers',
        addTrailerToContent: 'addtrailertocontent',
        deletetrailerfromcontent: 'deletetrailerfromcontent',
        listAllAwards: "listAllAwards",
        listAwardsCategory: "listAwardsCategory",
        attachAwardOnContent: "attachAwardOnContent",
        listAwardsByContent: "listAwardsByContent",
        updateAwardOnContent: "updateAwardOnContent",
        detachAwardOnContent: "detachAwardOnContent",
        listContentLanguages: "listcontentlanguage",
        createContentLanguage: "newcontentlanguage",
        deleteContentLangauge: "removecontetlangauge",
        listcountriesforcontent: "listcountriesforcontent",
        addcountrytocontent: "addcountrytocontent",
        removecontryfromcontent: "removecontryfromcontent"
    },
    organization_id: 1,
    reactQuery: {
        defaultOptions: {
            queries: {
                staleTime: 300000
            }
        },
        useAppConfig: {
            queryKey: "appData",
            staleTime: 300000,
        }
    },
    licenseKey: "a6HgCkGSKR3KafilrGEqqgQZB1BFqPV9ZPgOup9P7npAyQai"
}
